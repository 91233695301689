import React from 'react'
import DashboardComponent from '../../components/dashboard/Dashboard'
const Dashboard = ({ routes }) => {

    return (
        <DashboardComponent routes={routes} />
    )
}

export default Dashboard
