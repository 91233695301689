import React, { createContext, useEffect , useState } from 'react'
import apis from '../apis/http'
import instance from '../apis/http'


export const APIContext = createContext()

const Context = ({ children }) => {

    const [authenticated , setAuthenticated] = useState(false)
    const [user , setUser] = useState({})
    const [factories , setFactories] = useState([])
    const [activities , setActivities] = useState([])
    const [drainTypes , setDrainTypes] = useState([])
    const [industrialZones , setIndustrialZones] = useState([])
    const [posts , setPosts] = useState([])
    const [aboutUs , setAboutUs] = useState([])
    const [team , setTeam] = useState([])
    const [testimonials , setTestimonials] = useState([])
    const [info , setInfo] = useState({})

    useEffect(() => {

        const checkAuthentication = async () => {
            await instance.checkAuth().then((res) => {
                setAuthenticated(true)
                setUser(res.data.user)
            })
            .catch(err => {
                if(window.location.pathname !== '/login')
                window.location.href = "/login"
            })
        }

        const fetchFactories = async () => {
            await apis.fetchFactories().then(res => setFactories(res.data)).catch(err => {})
        }

        const fetchIndustrialZones = async () => {
            await apis.fetchIndustrialZones().then(res => setIndustrialZones(res.data)).catch(err => {})
        }

        const fetchActivities = async () => {
            await apis.fetchActivities().then(res => setActivities(res.data)).catch(err => {})
        }

        const fetchDrainTypes = async () => {
            await apis.fetchDrainTypes().then(res => setDrainTypes(res.data)).catch(err => {})
        }


        checkAuthentication()

        fetchFactories()

        fetchIndustrialZones()

        fetchActivities()

        fetchDrainTypes()

        // fetchAboutUs()

        // fetchTeam()

        // fetchTestimonials()

        // fetchInfo()

    } , [])

    return (
        <APIContext.Provider value={{ 
            authenticated,
            setAuthenticated,
            user,
            setUser,
            factories,
            setFactories,
            activities,
            setActivities,
            industrialZones,
            setIndustrialZones,
            info,
            setInfo,
            drainTypes,
            setDrainTypes
             }}>
            { children }
        </APIContext.Provider>
    )
}

export default Context
