import React, { useContext, useState } from 'react'
import { APIContext } from '../../context/Context'
import apis from '../../apis/http'
import { useSnackbar } from 'notistack'
import { DataGrid , GridToolbar , useGridSlotComponentProps } from '@material-ui/data-grid';
import dateFormat from 'dateformat'
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import './FormGroup.css'


const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();
  
    return (
      <Pagination
        className={classes.root}
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

const Activities = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const { activities , setActivities } = useContext(APIContext)

    const [data , setData] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault()
        await apis.addActivity(data).then(res => {
            setActivities(activities => [...activities , res.data.result])
            alertMessage('success' , res.data.message)
            window.location.reload()
        }).catch(err => {
            alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`)
        })
    }

    const handleDelete = async (id) => {
        await apis.deleteActivity(id).then(() => {
            setActivities(activities => activities.filter(activity => activity.id !== id))
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'name', headerName: 'إسم النشاط', width: 200 },
        { field: 'status', headerName: 'الحالة', width: 200 },
        { field: 'created_at', headerName: 'تاريخ التسجيل', width: 250,
        valueGetter: (params) => dateFormat(params.created_at , 'dd/MM/yyyy, hh:MM:ss TT')
        },
        {
        field: 'edit',
        headerName: 'تعديل',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-edit" onClick={() => window.location.href = `/activity/${params.row.id}`} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'green' }}></i>)
        },
        {
        field: 'delete',
        headerName: 'حذف',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-trash" onClick={() => handleDelete(params.id)} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'red' }}></i>)
        },
      ];

    return (
        <div className="row" style={{ marginLeft: 10 , marginRight : 10 }}>
        <div className="col-md-12">
        <div className="panel panel-white">
        <div className="panel-heading">
            <h4 className="panel-title">النشاطات</h4>
        </div>
        <div className="panel-body">
            <button type="button" className="btn btn-success m-b-sm" data-toggle="modal" data-target="#myModal">إضافة نشاط</button>
            <form id="add-row-form" action="javascript:void(0);" onSubmit={(e) => handleSubmit(e)}>
            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">إضافة نشاط</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>إسم النشاط</h6>
                                <input type="text" className="form-control" defaultValue={data.name} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , name : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['name']
                                    return state
                                })} placeholder="إسم النشاط" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>الحالة</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , status : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['status']
                                    return state
                                })}>
                                    <option value="">اختر الحالة</option>
                                    <option value="0">لا يعمل</option>
                                    <option value="1">يعمل</option>
                                </select>
                            </div>
                            </div>


                           
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">إغلاق</button>
                            <button type="submit" onSubmit={(e) => handleSubmit(e)} className="btn btn-success">إضافة</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        <div style={{ height: 600, width: '100%' }}>
        {activities && activities.length > 0 ? <DataGrid rows={activities} columns={columns} pageSize={10} components={{
          Toolbar: GridToolbar,
          Pagination: CustomPagination
        }} /> : <div className="alert alert-danger" style={{ direction : 'rtl' }}>لا يوجد بيانات</div>}
        </div>
        </div>
    </div>
    </div>
    </div>
    )
}

export default Activities
