import React from 'react'
import EditPostComponent from '../../components/dashboard/EditPost'

const EditPost = () => {
    return (
        <EditPostComponent />
    )
}

export default EditPost
