import React from 'react'
import EditTestimonialComponent from '../../components/dashboard/EditTestimonial'

const EditTestimonial = () => {
    return (
        <EditTestimonialComponent />
    )
}

export default EditTestimonial
