import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apis from '../../apis/http'
import { APIContext } from '../../context/Context'

const EditFactory = () => {

    const { industrialZones , activities , drainTypes } = useContext(APIContext)

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };


    const { code } = useParams()

    const [factory , setFactory] = useState({})

    const [drainMeterStatus , setDrainMeterStatus] = useState(false)


    useEffect(() => {
        const fetchOneFactory = async () => {
            await apis.fetchFactory(code).then(res => {
                setFactory(res.data)
            }).catch(err => {
                window.location.href = "/factories"
            })
        }

        fetchOneFactory()
    } , [])

    const handleUpdate = async (e) => {
        e.preventDefault()
        await apis.editFactory(factory.id , factory).then(res => {
            alertMessage('success' , res.data.message)
            setTimeout(() => {
                window.location.reload()
            } , 2000)
        })
        .catch(err => {
            console.log(err.response.data)
            if(err.response.status === 422)
            alertMessage('error' , err.response.data.errors[0].message)
            else
            alertMessage('error' , err.response.data.message)
        })
    }

    return (
        Object.keys(factory).length !== 0 ?
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">تعديل منشأة</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">تعديل منشأة</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleUpdate(e)}>
                                    <div className="modal-body">
                                    <h3>البيانات الأساسية</h3>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>كود المنشأة</h6>
                                <input type="text" className="form-control" defaultValue={factory.code} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , code : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['code']
                                    return state
                                })} placeholder="كود المنشأة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المنشأة</h6>
                                <input type="text" className="form-control" defaultValue={factory.name} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , name : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['name']
                                    return state
                                })} placeholder="إسم المنشأة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>الاسم التجارى</h6>
                                <input type="text" className="form-control" defaultValue={factory.commercial_name} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , commercial_name : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['commercial_name']
                                    return state
                                })} placeholder="الاسم التجارى" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>العنوان</h6>
                                <textarea type="text" className="form-control" defaultValue={factory.address} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , address : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['address']
                                    return state
                                })} placeholder="العنوان" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>المنطقة الصناعية</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , industrial_zone_id : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['industrial_zone_id']
                                    return state
                                })}>
                                    <option value="">اختر المنطقة الصناعية</option>
                                    {
                                        industrialZones ? industrialZones.map((value , key) => (
                                            <option key={key} value={value.id} selected={value.id === factory.industrial_zone_id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>ايميل الشركة</h6>
                                <input type="email" className="form-control" defaultValue={factory.company_email} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , company_email : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['company_email']
                                    return state
                                })} placeholder="ايميل الشركة" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>تليفون ارضى الشركة</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.company_phone} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , company_phone : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['company_phone']
                                    return state
                                })} placeholder="تليفون ارضى الشركة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>موبايل الشركة</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.company_mobile} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , company_mobile : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['company_mobile']
                                    return state
                                })} placeholder="موبايل الشركة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>فاكس</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.fax} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , fax : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['fax']
                                    return state
                                })} placeholder="فاكس" />
                            </div>
                            </div>


                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>نوع المنتج</h6>
                                <input type="text" className="form-control" defaultValue={factory.product_type} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , product_type : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['product_type']
                                    return state
                                })} placeholder="نوع المنتج" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المنتج</h6>
                                <input type="text" className="form-control" defaultValue={factory.product_name} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , product_name : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['product_name']
                                    return state
                                })} placeholder="إسم المنتج" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المدير المسؤول</h6>
                                <input type="text" className="form-control" defaultValue={factory.contact_person_name} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , contact_person_name : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['contact_person_name']
                                    return state
                                })} placeholder="إسم المدير المسؤول" />
                            </div>
                            </div>

                            <hr />
                            <h3>البيانات الفرعية</h3>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>نوع النشاط</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , activity_id : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['activity_id']
                                    return state
                                })}>
                                    <option value="">اختر نوع النشاط</option>
                                    {
                                        activities ? activities.map((value , key) => (
                                            <option key={key} value={value.id} selected={value.id === factory.activity_id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>رقم الإشتراك</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.subscription_number} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , subscription_number : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['subscription_number']
                                    return state
                                })} placeholder="رقم الإشتراك" />
                            </div>
                            </div>


                            <div className="row">
                            
                            <div className="form-group col col-md-4">
                                <h6>موقف التعاقد</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.contract_position} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , contract_position : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['contract_position']
                                    return state
                                })} placeholder="موقف التعاقد" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>تاريخ التعاقد</h6>
                                <input type="date" className="form-control" defaultValue={factory.contract_date} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , contract_date : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['contract_date']
                                    return state
                                })} placeholder="تاريخ التعاقد" />
                            </div>
                            </div>



                            <div className="row">
                            
                            <div className="form-group col col-md-4">
                                <h6>حجم استهلاك المياه م3</h6>
                                <input type="number" pattern="[0-9]" step="any" className="form-control" defaultValue={factory.water_consumption} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , water_consumption : Math.abs(e.target.value),
                                    expected_drainage_size : Math.abs(e.target.value * 0.8)}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['water_consumption']
                                    return state
                                })} placeholder="حجم استهلاك المياه م3" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>حجم الصرف المتوقع</h6>
                                <input disabled type="number" pattern="[0-9]" step="any" className="form-control" defaultValue={factory.expected_drainage_size} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , expected_drainage_size : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['expected_drainage_size']
                                    return state
                                })} placeholder="حجم الصرف المتوقع" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>عداد الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => {
                                    if(e.target.value !== ''){
                                    setFactory({...factory , drain_meter : e.target.value})
                                    if(e.target.value === "0"){
                                    setFactory({...factory , drain_meter : false , actual_drain_size : factory.expected_drainage_size})
                                    setDrainMeterStatus(false)
                                    }
                                    else{
                                    setFactory({...factory , drain_meter : true})
                                    setDrainMeterStatus(true)
                                    }
                                    }
                                    else{
                                    setFactory(prevData => {
                                    const state = prevData
                                    delete state['drain_meter']
                                    return state
                                })
                                setDrainMeterStatus(false)
                                setFactory({...factory , actual_drain_size : factory.expected_drainage_size})
                            }
                                }}>
                                    <option value="">اختر الحالة</option>
                                    <option value="0" selected={Number(factory.drain_meter) === 0}>لا يوجد</option>
                                    <option value="1" selected={Number(factory.drain_meter) === 1}>يوجد</option>
                                </select>
                            </div>
                            
                            </div>


                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>رقم عداد الصرف</h6>
                                <input disabled={drainMeterStatus ? false : true} type="number" pattern="[0-9]" className="form-control" defaultValue={factory.drain_meter_no} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , drain_meter_no : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['drain_meter_no']
                                    return state
                                })} placeholder="رقم عداد الصرف" />
                            </div>
                            
                            <div className="form-group col col-md-4">
                                <h6>حجم الصرف الفعلي</h6>
                                <input disabled={drainMeterStatus ? false : true} type="number" pattern="[0-9]" className="form-control" value={factory.actual_drain_size} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , actual_drain_size : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['actual_drain_size']
                                    return state
                                })} placeholder="حجم الصرف الفعلي" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>نوع الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , drain_type_id : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['drain_type_id']
                                    return state
                                })}>
                                    <option value="">اختر نوع الصرف</option>
                                    {
                                        drainTypes ? drainTypes.map((value , key) => (
                                            <option key={key} value={value.id} selected={value.id === factory.drain_type_id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>معالجة الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , drain_processing : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['drain_processing']
                                    return state
                                })}>
                                    <option value="">اختر معالجة الصرف</option>
                                    <option selected={factory.drain_processing === 'غير معالج'} value="غير معالج">غير معالج</option>
                                    <option selected={factory.drain_processing === 'معالج'} value="معالج">معالج</option>
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>نوع المعالجة</h6>
                                <input type="string" className="form-control" defaultValue={factory.processing_type} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , processing_type : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['processing_type']
                                    return state
                                })} placeholder="نوع المعالجة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>مكان الصرف النهائى</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , final_drain_place : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['final_drain_place']
                                    return state
                                })}>
                                    <option value="">اختر مكان الصرف النهائى</option>
                                    <option selected={factory.final_drain_place === 'الشبكة العمومية'} value="الشبكة العمومية">الشبكة العمومية</option>
                                    <option selected={factory.final_drain_place === 'اعادة استخدام'} value="اعادة استخدام">اعادة استخدام</option>
                                    <option selected={factory.final_drain_place === 'رى'} value="رى">رى</option>
                                </select>
                            </div>

                            <div className="form-group col col-md-4">
                                <h6>عدد مخارج الصرف</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={factory.number_of_drain_exits} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , number_of_drain_exits : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['number_of_drain_exits']
                                    return state
                                })} placeholder="عدد مخارج الصرف" />
                            </div>
                            
                            <div className="form-group col col-md-4">
                                <h6>حالة المنشأة</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , status : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['status']
                                    return state
                                })}>
                                    <option value="">اختر الحالة</option>
                                    <option value="0" selected={Number(factory.status) === 0}>لا يعمل</option>
                                    <option value="1" selected={Number(factory.status) === 1}>يعمل</option>
                                </select>
                            </div>
                            
                            
                            <div className="form-group col col-md-4">
                                <h6>ملاحظات</h6>
                                <textarea type="text" className="form-control" defaultValue={factory.notes} 
                                onChange={(e) => e.target.value !== '' ? setFactory({...factory , notes : e.target.value}) : setFactory(prevData => {
                                    const state = prevData
                                    delete state['notes']
                                    return state
                                })} placeholder="ملاحظات" />
                            </div>

                            </div>


                        </div>
                        
  <button type="submit" className="btn btn-primary">تعديل</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
        :
        <></>
    )
}

export default EditFactory
