import instance from './axios'
const apis = {
    login : (data) => {
        return instance.post('/login' , data)
    },
    logout : () => {
        return instance.get('/logout')
    },
    checkAuth : () => {
        return instance.get('/checkAuth')
    },
    editProfile : (data) => {
        return instance.put('/editProfile' , data)
    },
    //Factories
    fetchFactories : () => {
        return instance.get('/factory')
    },
    deleteFactory : (id) => {
        return instance.delete(`/factory/${id}`)
    },
    addFactory : (data) => {
        return instance.post(`/factory` , data)
    },
    fetchFactory : (id) => {
        return instance.get(`/factory/${id}`)
    },
    editFactory : (id , data) => {
        return instance.put(`/factory/${id}` , data)
    },
    //Industrial zones
    fetchIndustrialZones : () => {
        return instance.get('/industrialZone')
    },
    deleteIndustrialZone : (id) => {
        return instance.delete(`/industrialZone/${id}`)
    },
    addIndustrialZone : (data) => {
        return instance.post(`/industrialZone` , data)
    },
    fetchIndustrialZone : (id) => {
        return instance.get(`/industrialZone/${id}`)
    },
    editIndustrialZone : (id , data) => {
        return instance.put(`/industrialZone/${id}` , data)
    },
    //Activities
    fetchActivities : () => {
        return instance.get('/activity')
    },
    deleteActivity : (id) => {
        return instance.delete(`/activity/${id}`)
    },
    addActivity : (data) => {
        return instance.post(`/activity` , data)
    },
    fetchActivity : (id) => {
        return instance.get(`/activity/${id}`)
    },
    editActivity : (id , data) => {
        return instance.put(`/activity/${id}` , data)
    },
    //DraiTypes
    fetchDrainTypes : () => {
        return instance.get('/drainType')
    },
    deleteDrainType : (id) => {
        return instance.delete(`/drainType/${id}`)
    },
    addDrainType : (data) => {
        return instance.post(`/drainType` , data)
    },
    fetchDrainType : (id) => {
        return instance.get(`/drainType/${id}`)
    },
    editDrainType : (id , data) => {
        return instance.put(`/drainType/${id}` , data)
    },
    //About Us
    fetchAboutUs : () => {
        return instance.get('/about-us')
    },
    deleteAboutUs : (id) => {
        return instance.delete(`/about-us/${id}`)
    },
    addAboutUs : (data) => {
        return instance.post(`/about-us` , data)
    },
    fetchAboutUsOne : (id) => {
        return instance.get(`/about-us/${id}`)
    },
    editAboutUs : (id , data) => {
        return instance.put(`/about-us/${id}` , data)
    },
    //Team
    fetchTeam : () => {
        return instance.get('/team')
    },
    deleteTeam : (id) => {
        return instance.delete(`/team/${id}`)
    },
    addTeam : (data) => {
        return instance.post(`/team` , data)
    },
    fetchTeamOne : (id) => {
        return instance.get(`/team/${id}`)
    },
    editTeam : (id , data) => {
        return instance.put(`/team/${id}` , data)
    },
    //Testimonials
    fetchTestimonials : () => {
        return instance.get('/testimonial')
    },
    deleteTestimonial : (id) => {
        return instance.delete(`/testimonial/${id}`)
    },
    addTestimonial : (data) => {
        return instance.post(`/testimonial` , data)
    },
    fetchTestimonial : (id) => {
        return instance.get(`/testimonial/${id}`)
    },
    editTestimonial : (id , data) => {
        return instance.put(`/testimonial/${id}` , data)
    },
    //Info
    fetchInfo : () => {
        return instance.get('/info')
    },
    editInfo : (data) => {
        return instance.put(`/info` , data)
    }
}

export default apis