import React, { useContext, useState } from 'react'
import { APIContext } from '../../context/Context'
import apis from '../../apis/http'
import { useSnackbar } from 'notistack'
import { DataGrid , GridToolbar , useGridSlotComponentProps } from '@material-ui/data-grid';
import dateFormat from 'dateformat'
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import './FormGroup.css'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();
  
    return (
      <Pagination
        className={classes.root}
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

const Factories = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const { factories , setFactories , industrialZones , activities , drainTypes } = useContext(APIContext)

    const [data , setData] = useState({})

    const [drainMeterStatus , setDrainMeterStatus] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(data)
        await apis.addFactory(data).then(res => {
            console.log(data)
            setFactories(factories => [...factories , res.data.result])
            alertMessage('success' , res.data.message)
            window.location.reload()
        }).catch(err => {
            console.log(err.response.data)
            alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`)
        })
    }

    const handleDelete = async (id) => {
        await apis.deleteFactory(id).then(() => {
            setFactories(factories => factories.filter(factory => factory.id !== id))
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'name', headerName: 'إسم المنشأة', width: 200 },
        { field: 'code', headerName: 'كود المنشأة', width: 200 },
        { field: 'created_at', headerName: 'تاريخ التسجيل', width: 250,
        valueGetter: (params) => dateFormat(params.created_at , 'dd/MM/yyyy, hh:MM:ss TT')
        },
        {
        field: 'edit',
        headerName: 'تعديل',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-edit" onClick={() => window.location.href = `/factory/${params.row.code}`} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'green' }}></i>)
        },
        {
        field: 'delete',
        headerName: 'حذف',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-trash" onClick={() => handleDelete(params.id)} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'red' }}></i>)
        },
      ];

    return (
        <div className="row" style={{ marginLeft: 10 , marginRight : 10 }}>
        <div className="col-md-12">
        <div className="panel panel-white">
        <div className="panel-heading">
            <h4 className="panel-title">المنشأت</h4>
        </div>
        <div className="panel-body">
            <button type="button" className="btn btn-success m-b-sm" data-toggle="modal" data-target="#myModal">إضافة منشأة</button>
            <form id="add-row-form" action="javascript:void(0);" onSubmit={(e) => handleSubmit(e)}>
            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">إضافة منشأة</h4>
                        </div>
                        <div className="modal-body">
                            <h3>البيانات الأساسية</h3>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>كود المنشأة</h6>
                                <input type="text" className="form-control" defaultValue={data.code} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , code : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['code']
                                    return state
                                })} placeholder="كود المنشأة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المنشأة</h6>
                                <input type="text" className="form-control" defaultValue={data.name} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , name : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['name']
                                    return state
                                })} placeholder="إسم المنشأة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>الاسم التجارى</h6>
                                <input type="text" className="form-control" defaultValue={data.commercial_name} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , commercial_name : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['commercial_name']
                                    return state
                                })} placeholder="الاسم التجارى" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>العنوان</h6>
                                <textarea type="text" className="form-control" defaultValue={data.address} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , address : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['address']
                                    return state
                                })} placeholder="العنوان" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>المنطقة الصناعية</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , industrial_zone_id : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['industrial_zone_id']
                                    return state
                                })}>
                                    <option value="">اختر المنطقة الصناعية</option>
                                    {
                                        industrialZones ? industrialZones.map((value , key) => (
                                            <option key={key} value={value.id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>ايميل الشركة</h6>
                                <input type="email" className="form-control" defaultValue={data.company_email} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , company_email : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['company_email']
                                    return state
                                })} placeholder="ايميل الشركة" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>تليفون ارضى الشركة</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.company_phone} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , company_phone : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['company_phone']
                                    return state
                                })} placeholder="تليفون ارضى الشركة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>موبايل الشركة</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.company_mobile} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , company_mobile : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['company_mobile']
                                    return state
                                })} placeholder="موبايل الشركة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>فاكس</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.fax} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , fax : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['fax']
                                    return state
                                })} placeholder="فاكس" />
                            </div>
                            </div>


                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>نوع المنتج</h6>
                                <input type="text" className="form-control" defaultValue={data.product_type} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , product_type : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['product_type']
                                    return state
                                })} placeholder="نوع المنتج" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المنتج</h6>
                                <input type="text" className="form-control" defaultValue={data.product_name} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , product_name : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['product_name']
                                    return state
                                })} placeholder="إسم المنتج" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>إسم المدير المسؤول</h6>
                                <input type="text" className="form-control" defaultValue={data.contact_person_name} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , contact_person_name : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['contact_person_name']
                                    return state
                                })} placeholder="إسم المدير المسؤول" />
                            </div>
                            </div>

                            <hr />
                            <h3>البيانات الفرعية</h3>
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>نوع النشاط</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , activity_id : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['activity_id']
                                    return state
                                })}>
                                    <option value="">اختر نوع النشاط</option>
                                    {
                                        activities ? activities.map((value , key) => (
                                            <option key={key} value={value.id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>رقم الإشتراك</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.subscription_number} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , subscription_number : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['subscription_number']
                                    return state
                                })} placeholder="رقم الإشتراك" />
                            </div>
                            </div>


                            <div className="row">
                            
                            <div className="form-group col col-md-4">
                                <h6>موقف التعاقد</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.contract_position} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , contract_position : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['contract_position']
                                    return state
                                })} placeholder="موقف التعاقد" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>تاريخ التعاقد</h6>
                                <input type="date" className="form-control" defaultValue={data.contract_date} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , contract_date : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['contract_date']
                                    return state
                                })} placeholder="تاريخ التعاقد" />
                            </div>
                            </div>



                            <div className="row">
                            
                            <div className="form-group col col-md-4">
                                <h6>حجم استهلاك المياه م3</h6>
                                <input type="number" pattern="[0-9]" step="any" className="form-control" defaultValue={data.water_consumption} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , water_consumption : Math.abs(e.target.value),
                                    expected_drainage_size : Math.abs(e.target.value * 0.8)}) : setData(prevData => {
                                    const state = prevData
                                    delete state['water_consumption']
                                    return state
                                })} placeholder="حجم استهلاك المياه م3" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>حجم الصرف المتوقع</h6>
                                <input disabled type="number" pattern="[0-9]" step="any" className="form-control" defaultValue={data.expected_drainage_size} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , expected_drainage_size : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['expected_drainage_size']
                                    return state
                                })} placeholder="حجم الصرف المتوقع" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>عداد الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => {
                                    if(e.target.value !== ''){
                                    setData({...data , drain_meter : e.target.value})
                                    if(e.target.value === "0"){
                                    setData({...data , drain_meter : false , actual_drain_size : data.expected_drainage_size})
                                    setDrainMeterStatus(false)
                                    }
                                    else{
                                    setData({...data , drain_meter : true})
                                    setDrainMeterStatus(true)
                                    }
                                    }
                                    else{
                                    setData(prevData => {
                                    const state = prevData
                                    delete state['drain_meter']
                                    return state
                                })
                                setDrainMeterStatus(false)
                                setData({...data , actual_drain_size : data.expected_drainage_size})
                            }
                                }}>
                                    <option value="">اختر الحالة</option>
                                    <option value="0">لا يوجد</option>
                                    <option value="1">يوجد</option>
                                </select>
                            </div>
                            
                            </div>


                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>رقم عداد الصرف</h6>
                                <input disabled={drainMeterStatus ? false : true} type="number" pattern="[0-9]" className="form-control" defaultValue={data.drain_meter_no} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , drain_meter_no : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['drain_meter_no']
                                    return state
                                })} placeholder="رقم عداد الصرف" />
                            </div>
                            
                            <div className="form-group col col-md-4">
                                <h6>حجم الصرف الفعلي</h6>
                                <input disabled={drainMeterStatus ? false : true} type="number" pattern="[0-9]" className="form-control" value={data.actual_drain_size} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , actual_drain_size : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['actual_drain_size']
                                    return state
                                })} placeholder="حجم الصرف الفعلي" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>نوع الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , drain_type_id : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['drain_type_id']
                                    return state
                                })}>
                                    <option value="">اختر نوع الصرف</option>
                                    {
                                        drainTypes ? drainTypes.map((value , key) => (
                                            <option key={key} value={value.id}>{value.name}</option>
                                        ))
                                        :
                                        <></>
                                    }
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>معالجة الصرف</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , drain_processing : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['drain_processing']
                                    return state
                                })}>
                                    <option value="">اختر معالجة الصرف</option>
                                    <option value="غير معالج">غير معالج</option>
                                    <option value="معالج">معالج</option>
                                </select>
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>نوع المعالجة</h6>
                                <input type="string" className="form-control" defaultValue={data.processing_type} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , processing_type : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['processing_type']
                                    return state
                                })} placeholder="نوع المعالجة" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>مكان الصرف النهائى</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , final_drain_place : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['final_drain_place']
                                    return state
                                })}>
                                    <option value="">اختر مكان الصرف النهائى</option>
                                    <option value="الشبكة العمومية">الشبكة العمومية</option>
                                    <option value="اعادة استخدام">اعادة استخدام</option>
                                    <option value="رى">رى</option>
                                </select>
                            </div>

                            <div className="form-group col col-md-4">
                                <h6>عدد مخارج الصرف</h6>
                                <input type="number" pattern="[0-9]" className="form-control" defaultValue={data.number_of_drain_exits} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , number_of_drain_exits : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['number_of_drain_exits']
                                    return state
                                })} placeholder="عدد مخارج الصرف" />
                            </div>
                            
                            <div className="form-group col col-md-4">
                                <h6>حالة المنشأة</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setData({...data , status : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['status']
                                    return state
                                })}>
                                    <option value="">اختر الحالة</option>
                                    <option value="0">لا يعمل</option>
                                    <option value="1">يعمل</option>
                                </select>
                            </div>
                            
                            
                            <div className="form-group col col-md-4">
                                <h6>ملاحظات</h6>
                                <textarea type="text" className="form-control" defaultValue={data.notes} 
                                onChange={(e) => e.target.value !== '' ? setData({...data , notes : e.target.value}) : setData(prevData => {
                                    const state = prevData
                                    delete state['notes']
                                    return state
                                })} placeholder="ملاحظات" />
                            </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">إلغاء</button>
                            <button type="submit" onClick={(e) => handleSubmit(e)} onSubmit={(e) => handleSubmit(e)} className="btn btn-success">إضافة</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        <div style={{ height: 600, width: '100%' }}>
            {factories && factories.length > 0 ? <DataGrid rows={factories} columns={columns} pageSize={10} components={{
          Toolbar: GridToolbar,
          Pagination: CustomPagination
        }} /> : <div className="alert alert-danger" style={{ direction : 'rtl' }}>لا يوجد بيانات</div>}
        </div>
        </div>
    </div>
    </div>
    </div>
    )
}

export default Factories
