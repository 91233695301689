import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { imgURL } from '../../apis/axios'
import apis from '../../apis/http'

const EditInfo = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const [info , setInfo] = useState({})

    const [logo , setLogo] = useState('')

    const [icon , setIcon] = useState('')

    const [phones , setPhones] = useState([""])

    const [social, setSocial] = useState([{ field: "", link: "" }]);

    useEffect(() => {
        const fetchInfo = async () => {
            await apis.fetchInfo().then(res => {
                console.log(res.data)
                setInfo({
                    ...res.data,
                    logo : '',
                    icon : '',
                    title : JSON.parse(res.data.title),
                    description : JSON.parse(res.data.description),
                    keywords : JSON.parse(res.data.keywords),
                    author : JSON.parse(res.data.author),
                    address : JSON.parse(res.data.address)
                })
                setLogo(res.data.logo)
                setIcon(res.data.icon)
                if(res.data.phones !== "" && JSON.parse(res.data.phones))
                setPhones(JSON.parse(res.data.phones))
                if(res.data.social !== "" && JSON.parse(res.data.social)){
                    var filtered = JSON.parse(res.data.social).filter(function (el) {
                        return el.field != "";
                      });
                setSocial(filtered)
                }
            }).catch(err => {

            })
        }

        fetchInfo()
    } , [])

    console.log(info)

    const handleUpdate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title' , JSON.stringify(info.title) || '')
        formData.append('description' , JSON.stringify(info.description) || '')
        formData.append('logo' , info.logo || '')
        formData.append('icon' , info.icon || '')
        formData.append('keywords' , JSON.stringify(info.keywords) || '')
        formData.append('author' , JSON.stringify(info.author) || '')
        formData.append('email' , info.email || '')
        formData.append('address' , JSON.stringify(info.address) || '')
        formData.append('phones' , JSON.stringify(phones))
        formData.append('social' , JSON.stringify(social))
        formData.append('map' , info.map || '')
        await apis.editInfo(formData).then(res => {
            res.data?.data?.logo?.fileName ? setLogo(res.data.data.logo.fileName) : <></>
            res.data?.data?.icon?.fileName ? setIcon(res.data.data.icon.fileName) : <></>
            alertMessage('success' , res.data.message)
            setTimeout(() => {
                window.location.reload()
            } , 2000)
        })
        .catch(err => {
            if(err.response.status === 422)
            alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`)
            else
            alertMessage('error' , err.response.data.message)
            console.log(err.response.data)

        })
    }

    return (
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">Edit Info</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">Edit Info</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleUpdate(e)}>
  <div className="form-group">
    <label>English Title</label>
    <input type="text" defaultValue={info?.title?.en} onChange={(e) => {
        setInfo({...info , title : {...info.title , en : e.target.value}})
    }} className="form-control" placeholder="Title" />
  </div>
  <div className="form-group">
    <label>Arabic Title</label>
    <input type="text" defaultValue={info?.title?.ar} onChange={(e) => {
        setInfo({...info , title : {...info.title , ar : e.target.value}})
    }} className="form-control" placeholder="Title" />
  </div>
  <div className="form-group">
    <label>English Description</label>
    <textarea defaultValue={info?.description?.en} onChange={(e) => {
        setInfo({...info , description : {...info.description , en : e.target.value}})
    }} className="form-control" placeholder="Description" />
  </div>
  <div className="form-group">
    <label>Arabic Description</label>
    <textarea defaultValue={info?.description?.ar} onChange={(e) => {
        setInfo({...info , description : {...info.description , ar : e.target.value}})
    }} className="form-control" placeholder="Description" />
  </div>
  <div className="form-group">
    <label>Logo</label>
    <input type="file" onChange={(e) => {
        setInfo({...info , logo : e.target.files[0]})
    }} className="form-control" placeholder="Logo" />
  </div>
  <img style={{ width : 250 }} src={`${imgURL}/${logo}`} />
  <div className="form-group">
    <label>Icon</label>
    <input type="file" onChange={(e) => {
        setInfo({...info , icon : e.target.files[0]})
    }} className="form-control" placeholder="Icon" />
  </div>
  <img style={{ width : 250 }} src={`${imgURL}/${icon}`} />
  <div className="form-group">
    <label>English Keywords</label>
    <textarea defaultValue={info?.keywords?.en} onChange={(e) => {
        setInfo({...info , keywords : {...info.keywords , en : e.target.value}})
    }} className="form-control" placeholder="Keywords" />
  </div>
  <div className="form-group">
    <label>Arabic Keywords</label>
    <textarea defaultValue={info?.keywords?.ar} onChange={(e) => {
        setInfo({...info , keywords : {...info.keywords , ar : e.target.value}})
    }} className="form-control" placeholder="Keywords" />
  </div>
  <div className="form-group">
    <label>English Author</label>
    <input type="text" defaultValue={info?.author?.en} onChange={(e) => {
        setInfo({...info , author : {...info.author , en : e.target.value}})
    }} className="form-control" placeholder="Author" />
  </div>
  <div className="form-group">
    <label>Arabic Author</label>
    <input type="text" defaultValue={info?.author?.ar} onChange={(e) => {
        setInfo({...info , author : {...info.author , ar : e.target.value}})
    }} className="form-control" placeholder="Author" />
  </div>
  <div className="form-group">
    <label>Email</label>
    <input type="email" defaultValue={info.email} onChange={(e) => {
        setInfo({...info , email : e.target.value})
    }} className="form-control" placeholder="Email" />
  </div>
  <div className="form-group">
    <label>English Address</label>
    <textarea defaultValue={info?.address?.en} onChange={(e) => {
        setInfo({...info , address : {...info.address , en : e.target.value}})
    }} className="form-control" placeholder="Address" />
  </div>
  <div className="form-group">
    <label>Arabic Address</label>
    <textarea defaultValue={info?.address?.ar} onChange={(e) => {
        setInfo({...info , address : {...info.address , ar : e.target.value}})
    }} className="form-control" placeholder="Address" />
  </div>
  <div className="form-group">
    <label>Map</label>
    <textarea defaultValue={info.map} onChange={(e) => {
        setInfo({...info , map : e.target.value})
    }} className="form-control" placeholder="Map" />
  </div>
  <div className="form-group">
        <label style={{ marginBottom : 10 , marginRight : 10 }}>Phones </label>
        <button type="button" onClick={() => setPhones([...phones , ""])} className="btn btn-danger">Add more</button>
  </div>
  {
      phones.map((value , key) => (
        <div className="form-group" key={key}>
        <input type="text" defaultValue={phones[key]} onChange={(e) => {
            const list = [...phones];
            list[--key] = e.target.value;
            var filtered = list.filter(function (el) {
                return el != "";
              });
            setPhones(filtered)
        }} className="form-control" placeholder={`Phone ${++key}`} />

      </div>
      ))
  }
  <div className="form-group">
        <label style={{ marginBottom : 10 , marginRight : 10 }}>Social </label>
        <button type="button" onClick={() => setSocial([...social , { field : "" , link : "" }])} className="btn btn-danger">Add more</button>
  </div>
  {
      social.map((value , key) => (
        <div className="form-group" key={key}>
        <input type="text" name="field" defaultValue={social[key].field} onChange={(e) => {
            const { name , value } = e.target;
            const list = [...social];
            list[--key][name] = value;
            var filtered = list.filter(function (el) {
                return el.field != "";
              });
            setSocial(filtered)
        }} className="form-control" placeholder={`Social ${++key}`} />
<br />
        <input type="text" name="link" defaultValue={social[--key].link} onChange={(e) => {
            const { name , value } = e.target;
            const list = [...social];
            list[--key][name] = value;
            var filtered = list.filter(function (el) {
                return el.link != "";
              });
            setSocial(filtered)
        }} className="form-control" placeholder={`Social ${++key}`} />

      </div>
      ))
  }
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
    )
}

export default EditInfo
