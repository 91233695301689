import React from 'react'
import EditIndustrialZoneComponent from '../../components/dashboard/EditIndustrialZone'

const EditIndustrialZone = () => {
    return (
        <EditIndustrialZoneComponent />
    )
}

export default EditIndustrialZone
