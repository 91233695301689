import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { imgURL } from '../../apis/axios'
import apis from '../../apis/http'

const EditPost = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };


    const { id } = useParams()

    const [post , setPost] = useState({})

    const [image , setImage] = useState('')

    useEffect(() => {
        const fetchOnePost = async () => {
            await apis.fetchPost(id).then(res => {
                setPost({
                ...res.data,
                image : '',
                title : JSON.parse(res.data.title),
                description : JSON.parse(res.data.description)
            })
                setImage(res.data.image)
            }).catch(err => {
                window.location.href = "/posts"
            })
        }

        fetchOnePost()
    } , [])

    const handleUpdate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title' , JSON.stringify(post.title))
        formData.append('description' , JSON.stringify(post.description))
        formData.append('image' , post.image)
        formData.append('url' , post.url)
        await apis.editPost(id , formData).then(res => {
            res.data?.data?.image?.fileName ? setImage(res.data.data.image.fileName) : <></>
            alertMessage('success' , res.data.message)
            setTimeout(() => {
                window.location.reload()
            } , 2000)
        })
        .catch(err => {
            if(err.response.status === 422)
            alertMessage('error' , err.response.data.errors[0].message)
            else
            alertMessage('error' , err.response.data.message)

            console.log(err.response.data)
        })
    }

    return (
        Object.keys(post).length !== 0 ?
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">Edit Post</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">Edit Post</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleUpdate(e)}>
    <div className="form-group">
    <label>English Title</label>
    <input type="text" defaultValue={post.title.en} onChange={(e) => {
        setPost({...post , title : {...post.title , en : e.target.value}})
    }} className="form-control" placeholder="Title" />
  </div>
  <div className="form-group">
    <label>Arabic Title</label>
    <input type="text" defaultValue={post.title.ar} onChange={(e) => {
        setPost({...post , title : {...post.title , ar : e.target.value}})
    }} className="form-control" placeholder="Title" />
  </div>
  <div className="form-group">
    <label>English Description</label>
    <input type="text" defaultValue={post.description.en} onChange={(e) => {
        setPost({...post , description : {...post.description , en :e.target.value}})
    }} className="form-control" placeholder="Description" />
  </div>
  <div className="form-group">
    <label>Arabic Description</label>
    <input type="text" defaultValue={post.description.ar} onChange={(e) => {
        setPost({...post , description : {...post.description , ar :e.target.value}})
    }} className="form-control" placeholder="Description" />
  </div>
  <div className="form-group">
    <label>Image</label>
    <input type="file" onChange={(e) => {
        setPost({...post , image : e.target.files[0]})
    }} className="form-control" placeholder="Image" />
  </div>
  <img style={{ width : 250 }} src={`${imgURL}/${image}`} />
  <div className="form-group">
    <label>URL</label>
    <input type="text" defaultValue={post.url} onChange={(e) => {
        setPost({...post , url : e.target.value})
    }} className="form-control" placeholder="URL" />
  </div>
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
        :
        <></>
    )
}

export default EditPost
