import React from 'react'
import LoginComponent from '../../components/dashboard/Login'

const Login = () => {
    return (
            <LoginComponent />
    )
}

export default Login
