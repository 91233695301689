import React from 'react'
import EditFactoryComponent from '../../components/dashboard/EditFactory'

const EditFactory = () => {
    return (
        <EditFactoryComponent />
    )
}

export default EditFactory
