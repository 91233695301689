import React from 'react'
import EditDrainTypeComponent from '../../components/dashboard/EditDrainType'

const EditDrainType = () => {
    return (
        <EditDrainTypeComponent />
    )
}

export default EditDrainType
