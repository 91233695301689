import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apis from '../../apis/http'

const EditActivity = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };


    const { id } = useParams()

    const [activity , setActivity] = useState({})


    useEffect(() => {
        const fetchOneIndustrialZone = async () => {
            await apis.fetchActivity(id).then(res => {
                setActivity(res.data)
            }).catch(err => {
                window.location.href = "/activities"
            })
        }

        fetchOneIndustrialZone()
    } , [])

    const handleUpdate = async (e) => {
        e.preventDefault()
        await apis.editActivity(activity.id , activity).then(res => {
            alertMessage('success' , res.data.message)
            setTimeout(() => {
                window.location.reload()
            } , 2000)
        })
        .catch(err => {
            if(err.response.status === 422)
            alertMessage('error' , err.response.data.errors[0].message)
            else
            alertMessage('error' , err.response.data.message)
        })
    }

    return (
        Object.keys(activity).length !== 0 ?
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">تعديل النشاط</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">تعديل النشاط</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleUpdate(e)}>
                                    <div className="modal-body">
                            <div className="row">
                            <div className="form-group col col-md-4">
                                <h6>إسم النشاط</h6>
                                <input type="text" className="form-control" defaultValue={activity.name} 
                                onChange={(e) => e.target.value !== '' ? setActivity({...activity , name : e.target.value}) : setActivity(prevData => {
                                    const state = prevData
                                    delete state['name']
                                    return state
                                })} placeholder="إسم النشاط" />
                            </div>
                            <div className="form-group col col-md-4">
                                <h6>الحالة</h6>
                                <select className="form-control" 
                                onChange={(e) => e.target.value !== '' ? setActivity({...activity , status : e.target.value}) : setActivity(prevData => {
                                    const state = prevData
                                    delete state['status']
                                    return state
                                })}>
                                    <option value={0} selected={Number(activity.status) === 0 ? true : false}>لا يعمل</option>
                                    <option value={1} selected={Number(activity.status) === 1 ? true : false}>يعمل</option>
                                </select>
                            </div>
                            </div>
      
                        </div>
                        
  <button type="submit" className="btn btn-primary">تعديل</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
        :
        <></>
    )
}

export default EditActivity
