import React from 'react'
import EditInfoComponent from '../../components/dashboard/EditInfo'

const EditInfo = () => {
    return (
        <EditInfoComponent />
    )
}

export default EditInfo
