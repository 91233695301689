import React, { useState } from "react"
import instance from '../../apis/http'
import { useSnackbar } from 'notistack'

const Login = () => {

    const [email , setEmail] = useState('')

    const [password , setPassword] = useState('')

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const handleSubmit =  async (e) => {
        e.preventDefault()

      const formData = new FormData()

      formData.append('email' , email)

      formData.append('password' , password)

      await instance.login(formData).then(res => {
        alertMessage('success' , res.data.message)
        setTimeout(() => {
          localStorage.setItem('token' , res.data.token.token)
          window.location.href = "/dashboard"
        } , 2000)
      })
      .catch(err => alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`))
    }

return (
  <div className="page-container">
  <div className="page-inner login-page">
      <div id="main-wrapper" className="container-fluid">
          <div className="row">
              <div className="col-sm-6 col-md-3 login-box">
                  <h4 className="login-title">Sign in to your account</h4>
                  <form method="post" onSubmit={(e) => handleSubmit(e)}>
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email address</label>
                          <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Password</label>
                          <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </div>
                      <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Login</button>
                  </form>
              </div>
          </div>
      </div>

</div>
</div>
);
};

export default Login;