import React from 'react'
import EditActivityComponent from '../../components/dashboard/EditActivity'

const EditActivity = () => {
    return (
        <EditActivityComponent />
    )
}

export default EditActivity
