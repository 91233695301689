import React, { useContext, useState } from 'react'
import { imgURL } from '../../apis/axios'
import { APIContext } from '../../context/Context'
import apis from '../../apis/http'
import { useSnackbar } from 'notistack'
import { DataGrid } from '@material-ui/data-grid';
import dateFormat from 'dateformat'

const Team = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const { team , setTeam } = useContext(APIContext)

    const [name , setName] = useState({ en : "" , ar : ""})

    const [job , setJob] = useState({ en : "" , ar : ""})
    
    const [img , setImg] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        name.en && name.ar ? formData.append('name' , JSON.stringify(name)) : <></>
        job.en && job.ar ? formData.append('job' , JSON.stringify(job)) : <></>
        formData.append('image' , img)
        await apis.addTeam(formData).then(res => {
            setTeam(team => [...team , {
                id : res.data.data.id,
                name : res.data.data.name,
                job : res.data.data.job,
                image : res.data.data.image,
                created_at : res.data.data.created_at
            }])
            setName({ en : "" , ar : ""})
            setJob({ en : "" , ar : ""})
            setImg({})
            alertMessage('success' , res.data.message)
        }).catch(err => {
            alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`)
        })
    }

    const handleDelete = async (id) => {
        await apis.deleteTeam(id).then(() => {
            setTeam(team => team.filter(member => member.id !== id))
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'name', headerName: 'English Name', width: 200,
        renderCell: (params) => JSON.parse(params.value).en
        },
        { field: 'job', headerName: 'English Job', width: 200,
        renderCell: (params) => JSON.parse(params.value).en
        },
        { field: 'image', headerName: 'Image', width: 250,
        renderCell: (params) =>
        (<img style={{ width : 80 }} src={imgURL + '/' + params.value} />)
        },
        { field: 'created_at', headerName: 'Created At', width: 250,
        valueGetter: (params) => dateFormat(params.created_at , 'dddd, mmmm dS, yyyy, h:MM:ss TT')
        },
        {
        field: 'edit',
        headerName: 'Edit',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-edit" onClick={() => window.location.href = `/team/${params.id}`} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'green' }}></i>)
        },
        {
        field: 'delete',
        headerName: 'Delete',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-trash" onClick={() => handleDelete(params.id)} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'red' }}></i>)
        },
        
      ];

    return (
        <div className="row" style={{ marginLeft: 10 , marginRight : 10 }}>
        <div className="col-md-12">
        <div className="panel panel-white">
        <div className="panel-heading">
            <h4 className="panel-title">Team</h4>
        </div>
        <div className="panel-body">
            <button type="button" className="btn btn-success m-b-sm" data-toggle="modal" data-target="#myModal">Add member</button>
            <form id="add-row-form" action="javascript:void(0);" onSubmit={(e) => handleSubmit(e)}>
            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">Add member</h4>
                        </div>
                        <div className="modal-body">
                        <div className="form-group">
                                <input type="text" className="form-control" value={name.en} onChange={(e) => setName({...name , en : e.target.value})} placeholder="English Name" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={name.ar} onChange={(e) => setName({...name , ar : e.target.value})} placeholder="Arabic Name" />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" value={job.en} onChange={(e) => setJob({...job , en : e.target.value})} placeholder="English Job" />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" value={job.ar} onChange={(e) => setJob({...job , ar : e.target.value})} placeholder="Arabic Job" />
                            </div>
                            <div className="form-group">
                            <input type="file" className="form-control" onChange={(e) => setImg(e.target.files[0])} placeholder="Image" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" onSubmit={(e) => handleSubmit(e)} className="btn btn-success">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid rows={team} columns={columns} pageSize={10} />
        </div>
        </div>
    </div>
    </div>
    </div>
    )
}

export default Team
